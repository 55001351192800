<template>
    <div class="app-layout">
        <navbar :displayName="displayName" :isLoggedIn="isLoggedIn"/>
        <backend-updated-indicator></backend-updated-indicator>
        <div class="app-layout__content">
            <div class="app-layout__sidebar-wrapper" :class="{ minimized: isSidebarMinimized }">
                <div v-if="isFullScreenSidebar" class="d-flex justify--end">
                    <va-button
                        class="px-4 py-4"
                        icon="close"
                        flat
                        color="dark"
                        @click="onCloseSidebarButtonClick"
                    />
                </div>
                <sidebar
                    v-if="isLoggedIn"
                    :width="sidebarWidth"
                    :minimized="isSidebarMinimized"
                    :minimizedWidth="sidebarMinimizedWidth"
                />
            </div>
            <div class="app-layout__page">
                <div class="p-2 md:px-6 md:py-9">
                    <loading v-model:active="isAppLoading" :can-cancel="false" :is-full-page="true"/>
                    <router-view :key="this.$route.path" v-if="!isAppLoading"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import AuthHelper from '@/repositories/AuthHelper'
import BackendUpdatedIndicator from "@/components/BackendUpdatedIndicator";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'app-layout',
    components: {
        BackendUpdatedIndicator, Sidebar, Navbar, Loading
    },
    setup() {
        const store = useStore()
        const mobileBreakPointPX = 640
        const tabletBreakPointPX = 1024
        const sidebarWidth = ref('16rem')
        const sidebarMinimizedWidth = ref(undefined)
        const isAppLoading = ref(true)

        const displayName = computed(() => store.state.displayName)
        const isLoggedIn = computed(() => store.state.isLoggedIn)
        const isMobile = ref(false)
        const isTablet = ref(false)
        const isSidebarMinimized = computed(() => store.state.isSidebarMinimized)
        const checkIsTablet = () => window.innerWidth <= tabletBreakPointPX
        const checkIsMobile = () => window.innerWidth <= mobileBreakPointPX
        const onResize = () => {
            store.commit('updateSidebarCollapsedState', checkIsTablet())
            isMobile.value = checkIsMobile()
            isTablet.value = checkIsTablet()
            sidebarMinimizedWidth.value = isMobile.value ? 0 : '3.75rem'
            sidebarWidth.value = isTablet.value ? '100%' : '16rem'
        }
        onMounted(() => {
            window.addEventListener('resize', onResize)
        })
        onBeforeUnmount(() => {
            window.removeEventListener('resize', onResize)
        })
        onResize()
        const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)
        const onCloseSidebarButtonClick = () => {
            store.commit('updateSidebarCollapsedState', true)
        }
        return {
            isSidebarMinimized,
            sidebarWidth,
            sidebarMinimizedWidth,
            isFullScreenSidebar,
            onCloseSidebarButtonClick,
            displayName,
            isLoggedIn,
            isAppLoading
        }
    },
    beforeCreate() {
        if (!this.$store.state.isLoggedIn) {
            AuthHelper.refresh().catch(() => {
                this.$router.push({name: 'LogIn'})
            }).finally(() => {
                this.isAppLoading = false
            })
        } else {
            this.isAppLoading = false
        }
    }
}
</script>

<style lang="scss">
@import 'scss/main.scss';

:root {
    --va-font-family: 'Source Sans Pro', Avenir, Helvetica, Arial, sans-serif !important;
}
body {
    line-height: 1.4rem !important;
}

#app {
    font-family: 'Source Sans Pro', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 1024px;
.app-layout {
    height: 100vh;
    display: flex;
    flex-direction: column;

    &__navbar {
        min-height: 4rem;
    }

    &__content {
        display: flex;
        height: calc(100vh - 4rem);
        flex: 1;
        @media screen and (max-width: $tabletBreakPointPX) {
            height: calc(100vh - 6.5rem);
        }

        .app-layout__sidebar-wrapper {
            position: relative;
            height: 100%;
            background: var(--va-white);
            @media screen and (max-width: $tabletBreakPointPX) {
                &:not(.minimized) {
                    width: 100%;
                    height: 100%;
                    position: fixed;
                    top: 0;
                    z-index: 999;
                }
                .va-sidebar:not(.va-sidebar--minimized) {
                    // Z-index fix for preventing overflow for close button
                    z-index: -1;

                    .va-sidebar__menu {
                        padding: 0;
                    }
                }
            }
        }
    }

    &__page {
        flex-grow: 2;
        overflow-y: scroll;
        background-color: white;
    }
}

h1.page-title {
    font-size: 28px;
    text-align: left;
}

.va-card {
    &__title {
        font-size: 16px !important;
    }
}

.va-modal__title {
    font-size: 1.2em !important;
}

.key-value-table {
    width: 100%;

    td:first-child {
        font-size: .725rem;
        font-weight: 700;
        letter-spacing: .6px;
        line-height: 1.6;
        text-transform: uppercase;
        width: 35%;
    }
}

.va-breadcrumb-item {
    font-family: var(--va-font-family);
    font-size: 28px;
    line-height: 1.2em;
    font-weight: 600;
}
.va-breadcrumbs__separator {
    font-size: 28px;
    line-height: 1.2em;
}

// Common style for help panels
.help {
    p {
        margin-top: 0.5em;
    }
    .va-table {
        width: 100%
    }
    h4 {
        font-weight: 600!important;
    }
    h4:not(:first-child) {
        margin-top: 1.5rem!important;
    }
}

// Fix dropdowns showing up behind modals
.va-dropdown__content {
    z-index: 1001;
}

.cancel-button {
    color: rgb(118, 124, 136) !important;
}
</style>
