<template>
    <va-alert :color="color" class="mb-4" v-model="shown" :closeable="closeable">
        <span v-html="text"></span>
    </va-alert>
</template>

<script>
export default {
    name: 'AppAlert',
    props: {
        closeable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            text: '',
            color: '',
            shown: false,
            ttl: 0
        }
    },
    methods: {
        show(color, text, ttlSeconds = 0) {
            this.text = text
            this.color = color
            this.shown = true

            if (ttlSeconds > 0) {
                setTimeout(() => {this.hide()}, ttlSeconds * 1000)
            }
        },
        success(text, ttlSeconds = 0) {
            return this.show("success", text, ttlSeconds)
        },
        error(text, ttlSeconds = 0) {
            return this.show("danger", text.replaceAll('\n', '<br />'), ttlSeconds)
        },
        warning(text, ttlSeconds = 0) {
            return this.show("warning", text, ttlSeconds)
        },
        info(text, ttlSeconds = 0) {
            return this.show("primary", text, ttlSeconds)
        },
        hide() {
            this.shown = false
        }
    }
}
</script>

<style scoped lang="scss">
</style>
