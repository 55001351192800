import Client from '@/repositories/AxiosClient';

const resource = '/auth';

export default {
    list() {
        return Client().get(`${resource}/permissions`)
    },
    listPermissionSet(type) {
        return Client().get(`${resource}/permissions/${type}/`)
    },
    get(type, name) {
        return Client().get(`${resource}/permissions/${type}/${name}`)
    },
    getDetail(type, name) {
        return Client().get(`${resource}/permissions/${type}/${name}/detail`)
    },
    set(payload) {
        return Client().post(`${resource}/permissions/`, payload)
    },
    remove(type, name) {
        return Client().delete(`${resource}/permissions/${type}/${name}`)
    },
    getGroupMembers(name) {
        return Client().get(`${resource}/permissions/groups/${name}/members`)
    },
    modifyGroupMembers(name, payload) {
        return Client().put(`${resource}/permissions/groups/${name}/members`, payload)
    },
    listMachineTokens(studio_id) {
        const query = studio_id? `studio_id=${studio_id}` : ""
        return Client().get(`${resource}/machine_token?${query}`)
    },
    createMachineToken(studio_id, payload) {
        return Client().post(`${resource}/machine_token/${studio_id}`, payload)
    },
    revokeMachineToken(studio_id, token_id) {
        return Client().delete(`${resource}/machine_token/${studio_id}/${token_id}`)
    },
    getADGroups(studio_id) {
        return Client().get(`${resource}/groups/${studio_id}`)
    },
    getADGroupMembers(studio_id, name) {
        return Client().get(`${resource}/groups/${studio_id}/${name}`)
    },
    modifyADGroupMembers(studio_id, name, payload) {
        return Client().post(`${resource}/groups/${studio_id}/${name}`, payload)
    },
};
